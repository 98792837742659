// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-academic-offering-js": () => import("./../../../src/templates/academic-offering.js" /* webpackChunkName: "component---src-templates-academic-offering-js" */),
  "component---src-templates-department-js": () => import("./../../../src/templates/department.js" /* webpackChunkName: "component---src-templates-department-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-site-root-js": () => import("./../../../src/templates/site-root.js" /* webpackChunkName: "component---src-templates-site-root-js" */),
  "component---src-templates-standard-page-js": () => import("./../../../src/templates/standard-page.js" /* webpackChunkName: "component---src-templates-standard-page-js" */)
}

