// Require in project typefaces for speed, per
// https://spectrum.chat/gatsby-js/general/self-hosting-fonts-on-gatbsy-v2~971fa5bd-6a95-4ac6-bf92-aedc59ed1bab
// https://github.com/KyleAMathews/typefaces
require('typeface-lato')
require('typeface-bebas-neue')

import 'regenerator-runtime/runtime'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
    faAddressBook,
    faArrowCircleRight,
    faAtlas,
    faBook,
    faBookMedical,
    faBrain,
    faBriefcase,
    faCalculator,
    faCalendar,
    faCamera,
    faCar,
    faCashRegister,
    faChalkboard,
    faCheckSquare,
    faChevronRight,
    faCircle,
    faCoffee,
    faComment,
    faEnvelope,
    faEnvelopeOpenText,
    faExternalLinkSquareAlt,
    faFolderOpen,
    faGraduationCap,
    faHandshake,
    faHorse,
    faHorseHead,
    faInfoCircle,
    faLink,
    faMap,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faMapSigns,
    faMicroscope,
    faNewspaper,
    faPaperclip,
    faPhone,
    faPizzaSlice,
    faQuestionCircle,
    faSchool,
    faSuitcase,
    faTheaterMasks,
    faUser,
    faUserGraduate,
    faUserFriends,
    faUsers,
    faWallet,
    faWalking,
} from '@fortawesome/free-solid-svg-icons'

library.add(
    fab,
    faAddressBook,
    faArrowCircleRight,
    faAtlas,
    faBook,
    faBookMedical,
    faBrain,
    faBriefcase,
    faCalculator,
    faCalendar,
    faCamera,
    faCar,
    faCashRegister,
    faChalkboard,
    faCheckSquare,
    faChevronRight,
    faCircle,
    faCoffee,
    faComment,
    faEnvelope,
    faEnvelopeOpenText,
    faExternalLinkSquareAlt,
    faFolderOpen,
    faGraduationCap,
    faHandshake,
    faHorse,
    faHorseHead,
    faInfoCircle,
    faLink,
    faMap,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faMapSigns,
    faMicroscope,
    faNewspaper,
    faPaperclip,
    faPhone,
    faPizzaSlice,
    faQuestionCircle,
    faSchool,
    faSuitcase,
    faTheaterMasks,
    faUser,
    faUserGraduate,
    faUserFriends,
    faUsers,
    faWallet,
    faWalking
)
